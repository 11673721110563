<template >
    <div>
        <div class="flex-item ">
            <div class="backcolor">
                <span class="iconfont icon-fanhui" @click="getParents">返回</span>
            </div>
            <div class="backphoto">
                <div class="select" @click="getSelect">
                    <div class="title">{{check}}</div>
                    <span class="iconfont icon-xiala allClasss"></span>
                </div>
            </div>
        </div>
        <div class="padding" @click="getshow">
            <div class="check" v-if="show">
                <div class="left">已读 ({{ obj.read_num }}人)</div>
                <div class="lefts">未读 ({{ obj.unread_num }}人)</div>
            </div>
            <div class="check" v-else>
                <div class="rights">已读 ({{ obj.read_num }}人)</div>
                <div class="right">未读 ({{ obj.unread_num }}人)</div>
            </div>
        </div>
        <!-- 内容 -->
        <div class="padding" style="margin-top: 16px;" v-for="(item, index) in list" :key="index">
            <div>
                <span class="class">{{ item.grade_name }}{{ item.class_name }}</span>
                <span class="name">{{ item.user_name }}</span>
            </div>
            <div class="line"></div>
        </div>
        <div class="padding" v-if="this.list.length != 0">
            <div v-if="dataFromStore == user_id" class="btn" @click="getBtn" v-show="!show">
                一键提醒
            </div>
            <div v-else class="btns" v-show="!show">
                一键提醒
            </div>
        </div>

        <div>
            <van-popup v-model="showPicker" position="bottom" get-container="body">
                <van-picker show-toolbar :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
            </van-popup>
        </div>
        <div style="height: 49px;"></div>
        <div class="bottom">
            <div class="bottom_box">
                <van-icon @click="getUp" class="" name="arrow-left" />
                <van-icon class="icon" name="arrow" />
            </div>
        </div>
    </div>
</template >
<script>
import { Noticenotice, NoticeView, NoticeClasses } from "../../api/notice/notice";
import {getUserId} from "../../utils/user";

import { Notify } from "vant";
export default {
    data() {
        return {
            showPicker: false,
            columns: [
                {text: '查看全部', id: ''},
            ],
            show: true,
            obj: {},
            timeArr: [],
            btn: true,//未达到五次
            list: [],
            status: 1,
            user_id:'',
            check: '选择全部',
            class: [],
        }
    },
    computed: {
        dataFromStore() {
            return getUserId(); // 访问名为data的状态属性
        }
    },
    created() {
        let detailArr = JSON.parse(this.$route.query.str);
        this.obj = detailArr
        this.getList()
        this.getclass()
    },
    methods: {
        getParents() {
            this.$router.push("/noticeteach")
        },
        getSelect() {
            this.showPicker = true
        },
        onConfirm(value, index) {
            console.log(value);
            // 选择班级
            this.showPicker = false;
            this.check = value.text;
            this.obj.class_id = value.id
            this.getList()
        },
        getUp() {
            this.$router.push("/noticeteach")
        },
        getBtn() {
            Noticenotice({ id: this.obj.id }).then((res) => {
                if (res.code == 200) {
                    Notify({
                        message: '提醒成功',
                        duration: 1000,
                        background: '#1989fa'
                    });
                } else {
                    Notify({
                        message: res.msg,
                        duration: 1000,
                        background: '#1989fa'
                    });
                }
            })
        },
        getList() {
            this.list = []
            if (this.show) {
                this.status = 1
            } else {
                this.status = 0
            }
            NoticeView({ id: this.obj.id, status: this.status, class_id: this.obj.class_id }).then((res) => {
                this.list = res.data.user_list
                this.user_id = res.data.create_er
                this.obj.read_num = res.data.read_num
                this.obj.unread_num = res.data.unread_num
                console.log(this.obj);
            })
        },
        getshow() {
            this.show = !this.show
            this.getList()
        },
        getclass(){
            NoticeClasses().then((res) => {
                // console.log(res);
                res.data.map(item => {
                    // console.log(item);
                    this.columns.push( {text: item.name, id: item.id} )
                })
            })
        }
    }
}
</script>
<style lang="less" scoped>
.flex-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
}

.backcolor {
    color: #ffffff;
    width: 52px;
    height: 24px;
    border-radius: 12px;
    background-color: #AFAFAF;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        font-size: 12px;

    }
}

.select {
    height: 35px;
    text-align: center;
    line-height: 35px;
    color: #afafaf;
    border-radius: 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.title {
    color: #afafaf;
    font-size: 12px;
}

.allClasss {
    color: #afafaf;
    font-size: 12px;
    margin-left: 5px;
}

.padding {
    padding: 0 15px;

}

.check {
    width: 100%;
    height: 40px;
    background: #EDEDF5;
    border-radius: 20px 20px 20px 20px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
        width: 50%;
        height: 40px;
        background: #5380FF;
        opacity: 1;
        line-height: 40px;
        border-radius: 20px 20px 20px 20px;
        font-size: 15px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
    }

    .right {
        width: 50%;
        height: 40px;
        background: #5380FF;
        opacity: 1;
        line-height: 40px;
        border-radius: 20px 20px 20px 20px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        text-align: center;
    }
}

.bottom {
    position: fixed;
    bottom: 0;
    height: 49px;
    background: #F7F7F7;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .bottom_box {
        width: 120px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.icon {
    color: #999999;
    margin-left: 20px;
}

.class {
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;

}

.name {
    height: 22px;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #444444;
    margin-left: 24px;

}

.line {
    width: 100%;
    height: 0px;
    opacity: 1;
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin-top: 16px;
}

.btn {
    width: 100%;
    height: 36px;
    background: #5380FF;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
    text-align: center;
    margin-top: 45px;
}

.btns {
    width: 100%;
    height: 36px;
    background: #afafaf;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 36px;
    text-align: center;
    margin-top: 45px;
}

.lefts {
    width: 50%;
    height: 40px;
    opacity: 1;
    line-height: 40px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    text-align: center;
}

.rights {
    width: 50%;
    height: 40px;
    opacity: 1;
    line-height: 40px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    text-align: center;

}
</style>